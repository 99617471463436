import React from "react";
import { isMobile } from "react-device-detect";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useAtom } from "jotai";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@/components/List";
import useUser from "@/lib/useUser";
import {
    MENU_ENUM,
    RBSnackbar,
    toastMessageAtom,
    dialogMessageAtom,
} from "@/lib/util";
import fetchJson, { FetchError } from "@/lib/fetchJson";
const User = dynamic(() => import("./User"), {
    ssr: false,
});

//* This function should be out of Fictures component.
function HideOnScroll(props) {
    const { children, window } = props;

    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
};

export default function Fictures(props) {
    // console.log("call Fictures()");

    const { inputMode } = props;
    // console.log("inputMode: ", inputMode);

    function getMode({ mode }) {
        const result = Object.entries(MENU_ENUM).find(
            ([key, value]) => value === mode
        );
        if (result) return MENU_ENUM[mode];
        return;
    }

    const DEFAULT_MENU = MENU_ENUM.fictures;
    let MARKET_URL;
    if (process.env.NEXT_PUBLIC_BLOCKCHAIN_NETWORK === "maticmum") {
        MARKET_URL = "https://test-market.realbits.co";
    } else {
        MARKET_URL = "https://market.realbits.co";
    }
    const router = useRouter();
    const queryUpdated = router.query.updated;
    // console.log("router.query: ", router.query);
    // console.log("queryUpdated: ", queryUpdated);

    //* User login data.
    const { user, mutateUser } = useUser();
    // console.log("user: ", user);

    const [currentMode, setCurrentMode] = React.useState(
        inputMode || DEFAULT_MENU
    );
    const [newImageCount, setNewImageCount] = React.useState(0);
    const BUTTON_BORDER_RADIUS = 25;
    const SELECTED_BUTTON_PADDING = "2px 2px";

    //*---------------------------------------------------------------------------
    //* Setting menu variables.
    //*---------------------------------------------------------------------------
    const [settingMenuAnchorEl, setSettingMenuAnchorEl] = React.useState(null);
    const openSettingMenu = Boolean(settingMenuAnchorEl);
    function handleSettingMenuOpen(event) {
        setSettingMenuAnchorEl(event.currentTarget);
    }
    function handleSettingMenuClose() {
        setSettingMenuAnchorEl(null);
    }

    //*---------------------------------------------------------------------------
    //* Snackbar variables.
    //*---------------------------------------------------------------------------
    const [toastMessage, setToastMessage] = useAtom(toastMessageAtom);

    //*---------------------------------------------------------------------------
    //* Prompt dialog variables.
    //*---------------------------------------------------------------------------
    const [dialogMessage, setDialogMessage] = useAtom(dialogMessageAtom);

    function AppBarButton({ buttonMode }) {
        return (
            <Button
                key={buttonMode}
                onClick={() => {
                    setCurrentMode(buttonMode);
                }}
                sx={{ my: 2, color: "white" }}
            >
                {currentMode !== MENU_ENUM.fictures && <ArrowBackIosNewIcon />}
                {currentMode.toUpperCase()}
            </Button>
        );
    }

    function setNewBadgeOnImageAppBarButton({ newImageCount }) {
        // console.log("call setNewBadgeOnImageAppBarButton()");
        // console.log("newImageCount: ", newImageCount);
        setNewImageCount(newImageCount);
    }

    //* Propagate wagmi client into List component.
    return (
        <React.Fragment>
            {/*//*App bar menu. */}
            <HideOnScroll {...props}>
                <AppBar>
                    <Toolbar>
                        {currentMode === MENU_ENUM.fictures && (
                            <Box sx={{ flexGrow: 1, display: "block" }}></Box>
                        )}
                        <Box sx={{ flexGrow: 1, flexDirection: "row" }}>
                            <Badge
                                badgeContent={newImageCount}
                                color="secondary"
                                overlap="circular"
                            >
                                <AppBarButton buttonMode={MENU_ENUM.fictures} />
                            </Badge>
                        </Box>

                        <Box>
                            <User hidden={user?.isLoggedIn === true} />
                            {user?.isLoggedIn === true && (
                                <Button
                                    id="basic-button"
                                    aria-controls={
                                        openSettingMenu
                                            ? "basic-menu"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        openSettingMenu ? "true" : undefined
                                    }
                                    onClick={(event) => {
                                        if (
                                            (user !== undefined &&
                                                user.isLoggedIn === true) ||
                                            isMobile
                                        ) {
                                            handleSettingMenuOpen(event);
                                        }
                                    }}
                                    style={{
                                        borderRadius: BUTTON_BORDER_RADIUS,
                                        backgroundColor: null,
                                        padding: SELECTED_BUTTON_PADDING,
                                    }}
                                    sx={{ my: 2, color: "white" }}
                                >
                                    <MenuIcon />
                                </Button>
                            )}
                            <Menu
                                id="basic-menu"
                                anchorEl={settingMenuAnchorEl}
                                open={openSettingMenu}
                                onClose={handleSettingMenuClose}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setCurrentMode(MENU_ENUM.agent);
                                        handleSettingMenuClose();
                                    }}
                                >
                                    {MENU_ENUM.agent.toUpperCase()}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        setCurrentMode(MENU_ENUM.log);
                                        handleSettingMenuClose();
                                    }}
                                >
                                    {MENU_ENUM.log.toUpperCase()}
                                </MenuItem>

                                <Link
                                    href={MARKET_URL}
                                    target="_blank"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "inherit",
                                    }}
                                >
                                    <MenuItem
                                        onClick={() => {
                                            handleSettingMenuClose();
                                        }}
                                    >
                                        MARKET
                                    </MenuItem>
                                </Link>

                                <MenuItem
                                    onClick={() => {
                                        setCurrentMode(MENU_ENUM.setting);
                                        handleSettingMenuClose();
                                    }}
                                >
                                    {MENU_ENUM.setting.toUpperCase()}
                                </MenuItem>
                                <MenuItem
                                    onClick={async () => {
                                        setCurrentMode(MENU_ENUM.fictures);
                                        handleSettingMenuClose();

                                        try {
                                            mutateUser(
                                                await fetchJson(
                                                    { url: "/api/logout" },
                                                    { method: "POST" }
                                                ),
                                                false
                                            );
                                        } catch (error) {
                                            if (error instanceof FetchError) {
                                                console.error(
                                                    error.data.message
                                                );
                                            } else {
                                                console.error(
                                                    "An unexpected error happened:",
                                                    error
                                                );
                                            }
                                        }
                                    }}
                                >
                                    LOGOUT
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            {/*//*Image content part. */}
            <Box sx={{ my: 2 }}>
                <List
                    mode={currentMode}
                    updated={queryUpdated}
                    setNewImageCountFunc={setNewBadgeOnImageAppBarButton}
                />
            </Box>

            {/*//*Toast snackbar. */}
            <RBSnackbar
                open={toastMessage.open}
                message={toastMessage.message}
                severity={toastMessage.severity}
                currentTime={toastMessage.time}
            />

            {/*//*Prompt dialog. */}
            <Dialog
                open={dialogMessage.openDialog}
                onClose={() =>
                    setDialogMessage({
                        decyprtedPrompt: dialogMessage.decyprtedPrompt,
                        openDialog: false,
                    })
                }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Prompt</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage.decyprtedPrompt || ""}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() =>
                            setDialogMessage({
                                decyprtedPrompt: dialogMessage.decyprtedPrompt,
                                openDialog: false,
                            })
                        }
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
