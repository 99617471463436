import { useState, useEffect } from "react";
import { TextField, Button, Box } from "@mui/material";
import WalletProfile from "@/components/WalletProfile";

export default function Agent() {
    const [instruction, setInstruction] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [originalInstruction, setOriginalInstruction] = useState("");
    const [isModified, setIsModified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchInstruction = async () => {
            try {
                const response = await fetch("/api/agent");
                if (!response.ok) {
                    throw new Error("Failed to fetch instruction");
                }
                const data = await response.json();
                setInstruction(data.instruction || "");
                setOriginalInstruction(data.instruction || "");
            } catch (error) {
                console.error("Error fetching instruction:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchInstruction();
    }, []);

    useEffect(() => {
        setIsModified(instruction !== originalInstruction);
    }, [instruction, originalInstruction]);

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const response = await fetch("/api/agent", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ instruction }),
            });

            if (!response.ok) {
                throw new Error("Failed to save instructions");
            }
            
            setOriginalInstruction(instruction);
            setIsModified(false);
        } catch (error) {
            console.error("Error saving instructions:", error);
            // You might want to add error handling UI here
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <>
            <WalletProfile />
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
            >
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Instructions"
                    placeholder="Enter your instructions here..."
                    margin="normal"
                    variant="outlined"
                    value={instruction}
                    onChange={(e) => setInstruction(e.target.value)}
                    disabled={isLoading}
                />
                <Button
                    variant="contained"
                    disabled={!isModified || isSaving || isLoading}
                    onClick={handleSave}
                >
                    Save
                </Button>
            </Box>
        </>
    );
}
