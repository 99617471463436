import React from "react";
import { useReadContract } from "wagmi";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import rentmarketABI from "@/contracts/rentMarket.json";
import WalletProfile from "@/components/WalletProfile";
import ListItemNft from "@/components/ListItemNft";

function ListNft() {
    // console.log("call ListNft()");

    //*---------------------------------------------------------------------------
    //* Wagmi.
    //*---------------------------------------------------------------------------
    const RENTMARKET_CONTRACT_ADDRES =
        process.env.NEXT_PUBLIC_RENTMARKET_CONTRACT_ADDRESS;
    const PROMPTNFT_CONTRACT_ADDRESS =
        process.env.NEXT_PUBLIC_PROMPTNFT_CONTRACT_ADDRESS;

    const { data: dataAllRegisterData, isPending: isPendingAllRegisterData } =
        useReadContract({
            address: RENTMARKET_CONTRACT_ADDRES,
            abi: rentmarketABI.abi,
            functionName: "getAllRegisterData",
            watch: true,
        });

    function LoadingPage() {
        return (
            <Box
                sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <CircularProgress sx={{ width: "50vw" }} />
            </Box>
        );
    }

    if (isPendingAllRegisterData === true) {
        return <LoadingPage />;
    }

    return (
        <>
            <WalletProfile />

            <Box display="flex" justifyContent="center">
                <List
                    sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                    }}
                >
                    {dataAllRegisterData
                        .toReversed()
                        .map((registerData, idx) => {
                            if (
                                PROMPTNFT_CONTRACT_ADDRESS.toLowerCase() ===
                                registerData.nftAddress.toLowerCase()
                            ) {
                                return (
                                    <ListItemNft
                                        registerData={registerData}
                                        key={idx}
                                    />
                                );
                            }
                        })}
                </List>
            </Box>
        </>
    );
}

export default ListNft;
